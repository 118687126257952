import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import SLUGS from 'resources/slugs';
import LoadingComponent from 'components/loading';

const LoginComponent = lazy(() => import('./auth'));
const GuideComponent = lazy(() => import('./auth/GuideComponent'));
const CalculatedComponent = lazy(() => import('./auth/CalculatedComponent'));
const LogoutComponent = lazy(() => import('./auth/LogoutComponent'));

function PublicRoutes() {

    return (
        <Suspense fallback={<LoadingComponent loading />}>
        <Switch>
            <Route path={SLUGS.login.path} component={LoginComponent} />
            <Route path={SLUGS.guide.path} component={GuideComponent} />
            <Route path={SLUGS.calculate.path} component={CalculatedComponent} />
            <Route path={SLUGS.signup.path} component={LogoutComponent} />
            <Route path={SLUGS.forgotPassword.path} render={() => <div>forgotPassword</div>} />
            <Redirect to={SLUGS.login.path} />
        </Switch>
        </Suspense>
    );
}

export default PublicRoutes;
