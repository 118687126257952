import {API_GET_USER_PROFILE, API_USER_LOGIN, API_GET_CUSTOMER_ORDER_SUM, API_GET_CUSTOMER_BUY_SUM, API_USER_REGISTER, API_UPDATE_USER, API_DELETE_USER, API_ADD_CUSTOMER, API_UPDATE_CUSTOMER, API_DELETE_CUSTOMER, API_GET_USERS, API_GET_CUSTOMERS} from '../config/constant'
import cookie from 'react-cookies'

const getProfile = async (loginToken) => {
    const requestOptions = {
        // credentials: 'includes',
        headers : {
            token: loginToken
        }
    }

    const response = await fetch(API_GET_USER_PROFILE, requestOptions)
        .then((res) => res.json())
        .catch((error) => {
            console.log('[ERROR] fetch user profile failed, ', error)
        })

    // const result = response?.error && response.error === 0 ? response.data : initUserInfo

    return response?.data ? response.data : {}
}

const login = async (email, password) => {
    const payload = {
        "email": email,
        "password": password
    }

    const requestOptions = {
        method: 'POST',
        // credentials: 'includes',
        headers : {
            'Content-Type': 'application/json',
            "x-api-key": 'EE4A2ED01E4943BFB299BB87EB84C295'
        },
        body: JSON.stringify(payload)
    }

    const response = await fetch(API_USER_LOGIN, requestOptions)
        .then((res) => res.json())
        .catch((error) => {
            console.log('[ERROR] user login failed, ', error)
        })

    return response?.token ? response.token : undefined
}

const getCustomerOrderSum = async (loginToken) => {
    const requestOptions = {
        headers : {
            token: loginToken
        }
    }

    const response = await fetch(API_GET_CUSTOMER_ORDER_SUM, requestOptions)
        .then((res) => res.json())
        .catch((error) => {
            console.log('[ERROR] fetch customer order summary failed, ', error)
        })

    return response?.data ? response.data : []
}

const getCustomerInventoryBuySum = async (loginToken) => {
    const requestOptions = {
        headers : {
            token: loginToken
        }
    }

    const response = await fetch(API_GET_CUSTOMER_BUY_SUM, requestOptions)
        .then((res) => res.json())
        .catch((error) => {
            console.log('[ERROR] fetch customer inventory buy summary failed, ', error)
        })

    return response?.data ? response.data : []
}

const addCustomer = async (loginToken, customer_name, customer_no, customer_email, customer_phone, customer_address, memo = "" ) => {
    const lastName = cookie.load('LastName')
    const updater = lastName !== undefined && lastName !== '' ? lastName : 'System'

    const payload = {
        "customer_name": customer_name,
        "customer_no": customer_no,
        "customer_email": customer_email,
        "customer_phone": customer_phone,
        "customer_address": customer_address,
        "updater": updater
    }

    if(memo.length > 0) payload["memo"] = memo

    const requestOptions = {
        method: 'POST',
        headers : {
            'Content-Type': 'application/json',
            token: loginToken
        },
        body: JSON.stringify(payload)
    }


    const response = await fetch(`${API_ADD_CUSTOMER}`, requestOptions)
        .then((res) => res.json())
        .catch((error) => {
            console.log('[ERROR] Add a customer failed, ', error)
        })

    return response ? response : { "error": 1, "data": "Send add customer transaction failed."}
}

const updateCustomer = async (loginToken, customer_id, customer_name, customer_no, customer_email, customer_phone, customer_address, memo) => {
    const lastName = cookie.load('LastName')
    const updater = lastName !== undefined && lastName !== '' ? lastName : 'System'

    const payload = {
        "customer_name": customer_name,
        "customer_no": customer_no,
        "customer_email": customer_email,
        "customer_phone": customer_phone,
        "customer_address": customer_address,
        "memo": memo,
        "updater": updater
    }

    const requestOptions = {
        method: 'POST',
        headers : {
            'Content-Type': 'application/json',
            token: loginToken
        },
        body: JSON.stringify(payload)
    }

    const response = await fetch(`${API_UPDATE_CUSTOMER}${customer_id}`, requestOptions)
        .then((res) => res.json())
        .catch((error) => {
            console.log('[ERROR] Update a customer info failed, ', error)
        })

    return response ? response : { "error": 1, "data": "Send update customer info transaction failed."}
}

const deleteCustomer = async (loginToken, customerID) => {
    const lastName = cookie.load('LastName')
    const updater = lastName !== undefined && lastName !== '' ? lastName : 'System'

    const requestOptions = {
        method: 'DELETE',
        headers : {
            token: loginToken,
            updater: encodeURIComponent(updater)
        }
    }

    const response = await fetch(`${API_DELETE_CUSTOMER}${customerID}`, requestOptions)
        .then((res) => res.json())
        .catch((error) => {
            console.log(`[ERROR] Delete customer ID ${customerID} failed, `, error)
        })

    return response ? response : { "error": 1, "data": "Send delete customer transaction failed."}
}

const register = async (loginToken, first_name, last_name, email, password, role="General User") => {
    const lastName = cookie.load('LastName')
    const updater = lastName !== undefined && lastName !== '' ? lastName : 'System'

    const payload = {
        "first_name": first_name,
        "last_name": last_name,
        "email": email,
        "password": password,
        "role" : role,
        "updater": updater
    }

    const requestOptions = {
        method: 'POST',
        headers : {
            'Content-Type': 'application/json',
            token: loginToken
        },
        body: JSON.stringify(payload)
    }


    const response = await fetch(`${API_USER_REGISTER}`, requestOptions)
        .then((res) => res.json())
        .catch((error) => {
            console.log('[ERROR] Register a user failed, ', error)
        })

    return response ? response : { "error": 1, "data": "Send register transaction failed."}
}

const updateUser = async (loginToken, user_id, first_name, last_name, email, password, role) => {
    const lastName = cookie.load('LastName')
    const updater = lastName !== undefined && lastName !== '' ? lastName : 'System'

    const payload = {
        "first_name": first_name,
        "last_name": last_name,
        "email": email,
        "password": password,
        "role" : role,
        "updater": updater
    }

    const requestOptions = {
        method: 'POST',
        headers : {
            'Content-Type': 'application/json',
            token: loginToken
        },
        body: JSON.stringify(payload)
    }

    const response = await fetch(`${API_UPDATE_USER}${user_id}`, requestOptions)
        .then((res) => res.json())
        .catch((error) => {
            console.log('[ERROR] Update a user info failed, ', error)
        })

    return response ? response : { "error": 1, "data": "Send update user info transaction failed."}
}

const deleteUser = async (loginToken, userID) => {
    const lastName = cookie.load('LastName')
    const updater = lastName !== undefined && lastName !== '' ? lastName : 'System'

    const requestOptions = {
        method: 'DELETE',
        headers : {
            token: loginToken,
            updater: encodeURIComponent(updater)
        }
    }

    const response = await fetch(`${API_DELETE_USER}${userID}`, requestOptions)
        .then((res) => res.json())
        .catch((error) => {
            console.log(`[ERROR] Delete user ID ${userID} failed, `, error)
        })

    return response ? response : { "error": 1, "data": "Send delete user transaction failed."}
}

const getUsers = async (loginToken) => {
    const requestOptions = {
        headers : {
            token: loginToken
        }
    }

    const response = await fetch(API_GET_USERS, requestOptions)
        .then((res) => res.json())
        .catch((error) => {
            console.log('[ERROR] fetch user list failed, ', error)
        })

    return response?.data ? response.data : []
}

const getCustomers = async (loginToken) => {
    const requestOptions = {
        headers : {
            token: loginToken
        }
    }

    const response = await fetch(API_GET_CUSTOMERS, requestOptions)
        .then((res) => res.json())
        .catch((error) => {
            console.log('[ERROR] fetch customer list failed, ', error)
        })

    return response?.data ? response.data : []
}

export {
    getProfile,
    login,
    register,
    updateUser,
    deleteUser,
    getCustomerOrderSum,
    getCustomerInventoryBuySum,
    addCustomer,
    updateCustomer,
    deleteCustomer,
    getUsers,
    getCustomers
}