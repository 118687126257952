import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import cookie from 'react-cookies';
import useWindowSize from 'hooks/useWindowSize';
import PrivateSection from 'routes/PrivateSection';
import PublicRoutes from 'routes/PublicRoutes';
import * as userApi from '../api/userApi'

function Routes() {
    const { pathname } = useLocation();
    // eslint-disable-next-line no-unused-vars
    const [width, height] = useWindowSize();
    const [isUserLoggedIn, setUserLoggedInFlag] = useState(false);
    const initUserInfo = {
        last_name: '訪客',
        role: '訪客'
    }
    const [userInfo, setUserProfile] = useState(initUserInfo)

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        async function fetchData() {
            const token = cookie.load('token')
            
            if(token !== undefined && token !== ''){
                const userProfile = await userApi.getProfile(token)

                console.log('userProfile = ', userProfile)
                if (userProfile && userProfile?.role && userProfile.role !== '訪客'){
                    // setUserLoggedInFlag(true)
                    setUserProfile(userProfile)
                }
            }
        }

        fetchData()
    }, []);

    useEffect(() => {
        if (userInfo && userInfo?.role && userInfo.role !== '訪客'){
            cookie.save('LastName', userInfo.last_name, {path: '/'})
            setUserLoggedInFlag(true)
        }
    }, [userInfo]);

    return isUserLoggedIn ? <PrivateSection /> : <PublicRoutes />;
}

export default Routes;
