import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import SLUGS from 'resources/slugs';
import LoadingComponent from 'components/loading';

const DashboardComponent = lazy(() => import('./dashboard'));
const ProductListComponent = lazy(() => import('./product'));
const RFQStatusComponent = lazy(() => import('./rfq/rfqStatus'));
const RFQHistComponent = lazy(() => import('./rfq/rfqHist'));
const CreateRFQComponent = lazy(() => import('./rfq/createRFQ'));
const ViewRFQComponent = lazy(() => import('./rfq/viewRFQ'));
const CreateOrderComponent = lazy(() => import('./order/createOrder'));
const OrderStatusComponent = lazy(() => import('./order/orderStatus'));
const ViewOrderComponent = lazy(() => import('./order/viewOrder'));
const OrderHistComponent = lazy(() => import('./order/orderHist'));
const BidInventoryComponent = lazy(() => import('./inventory/bidInventory'));
const AskInventoryComponent = lazy(() => import('./inventory/askInventory'));
const InventoryHistComponent = lazy(() => import('./inventory/inventoryHist'));
const InventoryStatusComponent = lazy(() => import('./inventory/inventoryStatus'));
const ProductStatusComponent = lazy(() => import('./inventory/productStatus'));
const TransInventoryComponent = lazy(() => import('./inventory/transInventory'));
const CustomerSumComponent = lazy(() => import('./order/customerSum'));
const CustomerInventoryBuySumComponent = lazy(() => import('./inventory/customerBuySum'));
const CustomerManageComponent = lazy(() => import('./auth/CustomerManageComponent'));
const UserManageComponent = lazy(() => import('./auth/UserManageComponent'));

function PrivateRoutes() {
    return (
        <Suspense fallback={<LoadingComponent loading />}>
            <Switch>
                <Route exact path={SLUGS.dashboard.path} component={DashboardComponent} />
                <Route exact path={SLUGS.metalStatus.path} component={InventoryStatusComponent} />
                <Route exact path={SLUGS.productStatus.path} component={ProductStatusComponent} />
                <Route exact path={SLUGS.bidInventory.path} component={BidInventoryComponent} />
                <Route exact path={SLUGS.askInventory.path} component={AskInventoryComponent} />
                <Route exact path={SLUGS.transInventory.path} component={TransInventoryComponent} />
                <Route exact path={SLUGS.inventoryHist.path} component={InventoryHistComponent} />
                <Route exact path={SLUGS.rfqStatus.path} component={RFQStatusComponent} />
                <Route exact path={SLUGS.createRFQ.path} component={CreateRFQComponent} />
                <Route exact path={SLUGS.rfqHist.path} component={RFQHistComponent} />
                <Route exact path={`${SLUGS.viewRFQ.path}/:rfqID`} component={ViewRFQComponent} />
                <Route exact path={SLUGS.orderStatus.path} component={OrderStatusComponent} />
                <Route exact path={SLUGS.createOrder.path} component={CreateOrderComponent} />
                <Route exact path={`${SLUGS.viewOrder.path}/:orderID`} component={ViewOrderComponent} />
                <Route exact path={SLUGS.orderHist.path} component={OrderHistComponent} />
                <Route exact path={SLUGS.product.path} component={ProductListComponent} />
                <Route exact path={SLUGS.settings.path} component={UserManageComponent} />
                <Route exact path={SLUGS.customerSum.path} component={CustomerSumComponent} />
                <Route exact path={SLUGS.customerBuySum.path} component={CustomerInventoryBuySumComponent} />
                <Route exact path={SLUGS.customers.path} component={CustomerManageComponent} />
                <Route exact path={`${SLUGS.viewCustomer.path}/:customerID`} render={() => <div>未完成, 待豐輝提供需求內容</div>} />
                <Redirect to={SLUGS.dashboard.path} />
            </Switch>
        </Suspense>
    );
}

export default PrivateRoutes;
