import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useHistory } from 'react-router-dom';
import cookie from 'react-cookies';
import SLUGS from 'resources/slugs';
import {
    IconAgents,
    IconArticles,
    IconContacts,
    IconIdeas,
    IconLogout,
    IconOverview,
    IconSettings,
    IconSubscription,
    IconTickets
} from 'assets/icons';
import { convertSlugToUrl } from 'resources/utilities';
import LogoComponent from './LogoComponent';
import Menu from './MenuComponent';
import MenuItem from './MenuItemComponent';

const useStyles = createUseStyles({
    separator: {
        borderTop: ({ theme }) => `1px solid ${theme.color.lightGrayishBlue}`,
        marginTop: 16,
        marginBottom: 16,
        opacity: 0.06
    }
});

function SidebarComponent() {
    const { push } = useHistory();
    const theme = useTheme();
    const classes = useStyles({ theme });
    const isMobile = true //window.innerWidth <= 1080;

    function logout() {
        cookie.remove('token')
        cookie.remove('LastName')
        window.location.href = '/';
    }

    function onClick(slug, parameters = {}) {
        push(convertSlugToUrl(slug, parameters));
    }

    return (
        <Menu isMobile={isMobile}>
            <div style={{ paddingTop: 30, paddingBottom: 30 }}>
                <LogoComponent />
            </div>
            <MenuItem
                id={SLUGS.dashboard.path}
                title={SLUGS.dashboard.title}
                icon={IconIdeas}
                onClick={() => onClick(SLUGS.dashboard.path)}
            />
            <MenuItem
                id={SLUGS.product.path}
                title={SLUGS.product.title}
                icon={IconSubscription}
                onClick={() => onClick(SLUGS.product.path)}
            />            
            <MenuItem
                id={SLUGS.productStatus.path}
                items={[SLUGS.bidInventory.path, SLUGS.askInventory.path, SLUGS.inventoryHist.path]}
                title='庫存管理'
                icon={IconOverview}
            >
                <MenuItem
                    id={SLUGS.metalStatus.path}
                    title={SLUGS.metalStatus.title}
                    level={2}
                    icon={IconOverview}
                    onClick={() => onClick(SLUGS.metalStatus.path)}
                />                
                <MenuItem
                    id={SLUGS.productStatus.path}
                    title={SLUGS.productStatus.title}
                    level={2}
                    icon={IconOverview}
                    onClick={() => onClick(SLUGS.productStatus.path)}
                />
                <MenuItem
                    id={SLUGS.bidInventory.path}
                    title={SLUGS.bidInventory.title}
                    level={2}
                    icon={IconAgents}
                    onClick={() => onClick(SLUGS.bidInventory.path)}
                />
                <MenuItem
                    id={SLUGS.askInventory.path}
                    title={SLUGS.askInventory.title}
                    level={2}
                    icon={IconAgents}
                    onClick={() => onClick(SLUGS.askInventory.path)}
                />
                <MenuItem
                    id={SLUGS.transInventory.path}
                    title={SLUGS.transInventory.title}
                    level={2}
                    icon={IconAgents}
                    onClick={() => onClick(SLUGS.transInventory.path)}
                />                
                <MenuItem
                    id={SLUGS.inventoryHist.path}
                    title={SLUGS.inventoryHist.title}
                    level={2}
                    icon={IconArticles}
                    onClick={() => onClick(SLUGS.inventoryHist.path)}
                /> 
                <MenuItem
                    id={SLUGS.customerBuySum.path}
                    title={SLUGS.customerBuySum.title}
                    level={2}
                    icon={IconAgents}
                    onClick={() => onClick(SLUGS.customerBuySum.path)}
                />               
            </MenuItem>
            <MenuItem
                id={SLUGS.rfqStatus.path}
                items={[SLUGS.createRFQ.path, SLUGS.rfqHist.path]}
                title='詢價單'
                icon={IconContacts}
            >
                <MenuItem
                    id={SLUGS.rfqStatus.path}
                    title={SLUGS.rfqStatus.title}
                    level={2}
                    icon={IconOverview}
                    onClick={() => onClick(SLUGS.rfqStatus.path)}
                />
                <MenuItem
                    id={SLUGS.createRFQ.path}
                    title={SLUGS.createRFQ.title}
                    level={2}
                    icon={IconAgents}
                    onClick={() => onClick(SLUGS.createRFQ.path)}
                />
                <MenuItem
                    id={SLUGS.rfqHist.path}
                    title={SLUGS.rfqHist.title}
                    level={2}
                    icon={IconArticles}
                    onClick={() => onClick(SLUGS.rfqHist.path)}
                />                
            </MenuItem> 
            <MenuItem
                id={SLUGS.orderStatus.path}
                items={[SLUGS.createOrder.path, SLUGS.orderHist.path, SLUGS.customerSum.path]}
                title='訂單管理'
                icon={IconTickets}
            >
                <MenuItem
                    id={SLUGS.orderStatus.path}
                    title={SLUGS.orderStatus.title}
                    level={2}
                    icon={IconOverview}
                    onClick={() => onClick(SLUGS.orderStatus.path)}
                />
                <MenuItem
                    id={SLUGS.createOrder.path}
                    title={SLUGS.createOrder.title}
                    level={2}
                    icon={IconAgents}
                    onClick={() => onClick(SLUGS.createOrder.path)}
                />
                <MenuItem
                    id={SLUGS.orderHist.path}
                    title={SLUGS.orderHist.title}
                    level={2}
                    icon={IconArticles}
                    onClick={() => onClick(SLUGS.orderHist.path)}
                />
                <MenuItem
                    id={SLUGS.customerSum.path}
                    title={SLUGS.customerSum.title}
                    level={2}
                    icon={IconAgents}
                    onClick={() => onClick(SLUGS.customerSum.path)}
                />                               
            </MenuItem>            
            {/* <MenuItem
                id={SLUGS.ideas}
                items={[SLUGS.ideasTwo, SLUGS.ideasThree]}
                title='Ideas'
                icon={IconIdeas}
            >
                <MenuItem
                    id={SLUGS.ideas}
                    title='Sub Item 1'
                    level={2}
                    icon={IconAgents}
                    onClick={() => onClick(SLUGS.ideas)}
                />
                <MenuItem
                    id={SLUGS.ideasTwo}
                    title='Sub Item 2'
                    level={2}
                    icon={IconContacts}
                    onClick={() => onClick(SLUGS.ideasTwo)}
                />
                <MenuItem
                    id={SLUGS.ideasThree}
                    title='Sub Item 3'
                    level={2}
                    icon={IconArticles}
                    onClick={() => onClick(SLUGS.ideasThree)}
                />
            </MenuItem>
            <MenuItem
                id={SLUGS.contacts}
                title='Contacts'
                icon={IconContacts}
                onClick={() => onClick(SLUGS.contacts)}
            />
            <MenuItem
                id={SLUGS.agents}
                title='Agents'
                icon={IconAgents}
                onClick={() => onClick(SLUGS.agents)}
            />
            <MenuItem
                id={SLUGS.articles}
                title='Articles'
                icon={IconArticles}
                onClick={() => onClick(SLUGS.articles)}
            />
            <MenuItem
                id={SLUGS.subscription}
                title='Subscription'
                icon={IconSubscription}
                onClick={() => onClick(SLUGS.subscription)}
            /> */}
            <div className={classes.separator}></div>
            <MenuItem
                id={SLUGS.settings.path}
                items={[SLUGS.customers.title, SLUGS.settings.title]}
                title='用戶管理'
                icon={IconAgents}
            >
                <MenuItem
                    id={SLUGS.customers.path}
                    title={SLUGS.customers.title}
                    level={2}
                    icon={IconContacts}
                    onClick={() => onClick(SLUGS.customers.path)}
                />
                <MenuItem
                    id={SLUGS.settings.path}
                    title={SLUGS.settings.title}
                    level={2}
                    icon={IconSettings}
                    onClick={() => onClick(SLUGS.settings.path)}
                />              
            </MenuItem>             

            <MenuItem id='logout' title='登出' icon={IconLogout} onClick={logout} />
        </Menu>
    );
}

export default SidebarComponent;
