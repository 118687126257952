export function convertSlugToUrl(slug, parameters) {
    let url = slug;
    Object.entries(parameters).forEach(([key, value]) => {
        url = url.replace(`:${key}`, value);
    });
    return url;
}

export function DateTime2String(date) {
    let dateString = ''
    const year = new Intl.DateTimeFormat('en', {year : 'numeric'}).format(date)
    const month = new Intl.DateTimeFormat('en', {month : '2-digit'}).format(date)
    const day = new Intl.DateTimeFormat('en', {day : '2-digit'}).format(date)
    const hours = `0${date.getHours()}`
    const minutes = `0${date.getMinutes()}`
    const seconds = `0${date.getSeconds()}`

    dateString = `${year}-${month}-${day} ${hours.slice(-2)}:${minutes.slice(-2)}:${seconds.slice(-2)}`
    return dateString
}

export function Date2String(date, type = 'Date') {
    let dateString = ''
    const year = new Intl.DateTimeFormat('en', {year : 'numeric'}).format(date)
    const month = new Intl.DateTimeFormat('en', {month : '2-digit'}).format(date)
    const day = new Intl.DateTimeFormat('en', {day : '2-digit'}).format(date)

    if(type === 'Date')
        dateString = `${year}-${month}-${day}`
    else
        dateString = `${year}-${month}-${day} 00:00:00`

    return dateString
}

export function formatToCurrency(amount, int = false) {
    if(!int)
        return (amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'); 
    else
        return (amount).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,'); 
}

export function floatTwo(amount) {
    const floatCount = 100 // 小數點後兩位
    return Math.round(amount * floatCount) / floatCount
}
