import React, { useContext } from 'react';
import { string } from 'prop-types';
// import { useHistory } from 'react-router-dom';
import cookie from 'react-cookies';
import { Row } from 'simple-flexbox';
import { createUseStyles, useTheme } from 'react-jss';
import { SidebarContext } from 'hooks/useSidebar';
import SLUGS from 'resources/slugs';
// import { IconBell, IconSearch } from 'assets/icons';
// import { IconIdeas } from 'assets/icons';
import DropdownComponent from 'components/dropdown';

const useStyles = createUseStyles((theme) => ({
    avatar: {
        height: 35,
        width: 35,
        minWidth: 35,
        borderRadius: 50,
        marginLeft: 14,
        border: `1px solid ${theme.color.lightGrayishBlue2}`,
        '@media (max-width: 768px)': {
            marginLeft: 14
        }
    },
    container: {
        height: 40
    },
    name: {
        ...theme.typography.itemTitle,
        textAlign: 'right',
        '@media (max-width: 768px)': {
            display: 'none'
        }
    },
    separator: {
        borderLeft: `1px solid ${theme.color.lightGrayishBlue2}`,
        marginLeft: 32,
        marginRight: 32,
        height: 32,
        width: 2,
        '@media (max-width: 768px)': {
            marginLeft: 14,
            marginRight: 0
        }
    },
    title: {
        ...theme.typography.title,
        '@media (max-width: 1080px)': {
            marginLeft: 50
        },
        '@media (max-width: 468px)': {
            fontSize: 20
        }
    },
    iconStyles: {
        cursor: 'pointer',
        marginLeft: 25,
        '@media (max-width: 768px)': {
            marginLeft: 12
        }
    }
}));

function HeaderComponent() {
    // const { push } = useHistory();
    const { currentItem } = useContext(SidebarContext);
    const theme = useTheme();
    const classes = useStyles({ theme });

    const name = currentItem.split('/')[1] //.replace('/','')

    let postFix = SLUGS[name] && SLUGS[name]?.headerPostFix ? SLUGS[name].headerPostFix : ''
    let title = SLUGS[name] && SLUGS[name]?.title ? `${SLUGS[name].title}${postFix}` : ''
    const userLastName = cookie.load('LastName') !== undefined ? cookie.load('LastName') : ''

    // switch (true) {
    //     case currentItem === SLUGS.dashboard.path:
    //         title = SLUGS.dashboard.title;
    //         break;
    //     case [SLUGS.productStatus, SLUGS.bidInventory, SLUGS.askInventory].includes(currentItem):
    //         title = 'Overview';
    //         break;
    //     case currentItem === SLUGS.tickets:
    //         title = 'Tickets';
    //         break;
    //     case [SLUGS.ideas, SLUGS.ideasTwo, SLUGS.ideasThree].includes(currentItem):
    //         title = 'Ideas';
    //         break;
    //     case currentItem === SLUGS.contacts:
    //         title = 'Contacts';
    //         break;
    //     case currentItem === SLUGS.agents:
    //         title = 'Agents';
    //         break;
    //     case currentItem === SLUGS.articles:
    //         title = 'Articles';
    //         break;
    //     case currentItem === SLUGS.subscription:
    //         title = 'Subscription';
    //         break;
    //     case currentItem === SLUGS.settings:
    //         title = 'Settings';
    //         break;
    //     default:
    //         title = '';
    // }

    // function onSettingsClick() {
    //     push(SLUGS.settings.path);
    // }

    function onLogoutClick() {
        cookie.remove('token')
        cookie.remove('LastName')
        window.location.href = '/';
    }

    return (
        <Row className={classes.container} vertical='center' horizontal='space-between'>
            <span className={classes.title}>{title}</span>
            <Row vertical='center'>
                <div className={classes.iconStyles}>
                    {/* <IconIdeas /> */}
                    <a rel="noopener noreferrer" href={process.env.PUBLIC_URL + '/TaiwanSilver_ERP_User_Manual.pdf'} target="_blank" title="使用者手冊">
                    <img
                                src={process.env.PUBLIC_URL + '/manual1.png'}
                                alt='avatar'
                                className={classes.avatar}
                    />
                    </a>
                </div>
                {/* <div className={classes.iconStyles}>
                    <DropdownComponent
                        label={<IconBell />}
                        options={[
                            {
                                label: 'Notification #1',
                                onClick: () => console.log('Notification #1')
                            },
                            {
                                label: 'Notification #2',
                                onClick: () => console.log('Notification #2')
                            },
                            {
                                label: 'Notification #3',
                                onClick: () => console.log('Notification #3')
                            },
                            {
                                label: 'Notification #4',
                                onClick: () => console.log('Notification #4')
                            }
                        ]}
                        position={{
                            top: 42,
                            right: -14
                        }}
                    />
                </div> */}
                <div className={classes.separator}></div>
                <DropdownComponent
                    label={
                        <>
                            <span className={classes.name}>管理員 {userLastName} 您好</span>
                            <img
                                src={process.env.PUBLIC_URL + '/user.jpg'}
                                alt='avatar'
                                className={classes.avatar}
                            />
                        </>
                    }
                    options={[
                        // {
                        //     label: 'Settings',
                        //     onClick: onSettingsClick
                        // },
                        {
                            label: 'Logout',
                            onClick: onLogoutClick
                        }
                    ]}
                    position={{
                        top: 52,
                        right: -6
                    }}
                />
            </Row>
        </Row>
    );
}

HeaderComponent.propTypes = {
    title: string
};

export default HeaderComponent;
