export default {
    // private
    dashboard: {path: '/dashboard', title: '總覽'},
    product: {path: '/product', title: '產品品項'},
    productStatus: {path: '/productStatus', title: '商品庫存狀態'},
    metalStatus: {path: '/metalStatus', title: '金屬庫存狀態'},
    bidInventory: {path: '/bidInventory', title: '手動入庫'},
    askInventory: {path: '/askInventory', title: '手動出庫'},
    transInventory: {path: '/transInventory', title: '產品轉換'},
    inventoryHist: {path: '/inventoryHist', title: '庫存歷史'},
    customerBuySum: {path: '/customerBuySum', title: '客戶回收統計'},
    rfqStatus: {path: '/rfqStatus', title: '詢價單狀態', headerPostFix: '(30 天內)'},
    createRFQ: {path: '/createRFQ', title: '建立詢價單'},
    viewRFQ: {path: '/viewRFQ', title: '詢價單內容'},
    rfqHist: {path: '/rfqHist', title: '詢價單歷史'},
    orderStatus: {path: '/orderStatus', title: '訂單狀態'},
    createOrder: {path: '/createOrder', title: '建立訂單'},
    orderHist: {path: '/orderHist', title: '訂單歷史'},
    viewOrder: {path: '/viewOrder', title: '訂單內容'},
    customerSum: {path: '/customerSum', title: '客戶訂單統計'},
    // auth
    settings: {path: '/settings', title: '管理者設定'},
    customers: {path: '/customers', title: '客戶管理'},
    viewCustomer: {path: '/viewCustomer', title: '客戶買入銷售狀況'},
    guide: {path: '/guide', title: '首頁'},
    calculate: {path: '/calculate', title: '簡易計算'},
    login: {path: '/login', title: '登入'},
    signup: {path: '/signup', title: '註冊'},
    forgotPassword: {path: '/forgot_password', title: '忘記密碼'}
};
