export const API_ENG = 'prod'
export const API_URL_PREFIX = API_ENG === 'local' ? 'http://localhost:12350' : ''

export const API_USER_LOGIN = `${API_URL_PREFIX}/api/users/login`
export const API_USER_REGISTER = `${API_URL_PREFIX}/api/users/register`
export const API_UPDATE_USER = `${API_URL_PREFIX}/api/users/updateUser/`
export const API_DELETE_USER = `${API_URL_PREFIX}/api/users/deleteUser/`
export const API_GET_USERS = `${API_URL_PREFIX}/api/users/getUsers`
export const API_GET_USER_PROFILE = `${API_URL_PREFIX}/api/users/profile`
export const API_GET_CUSTOMER_ORDER_SUM = `${API_URL_PREFIX}/api/users/customerOrderSum`
export const API_GET_CUSTOMER_BUY_SUM = `${API_URL_PREFIX}/api/users/customerInventoryBuySum`
export const API_ADD_CUSTOMER = `${API_URL_PREFIX}/api/users/addCustomer`
export const API_UPDATE_CUSTOMER = `${API_URL_PREFIX}/api/users/updateCustomer/`
export const API_DELETE_CUSTOMER = `${API_URL_PREFIX}/api/users/deleteCustomer/`
export const API_GET_CUSTOMERS = `${API_URL_PREFIX}/api/users/getCustomers`

export const API_GET_RFQS = `${API_URL_PREFIX}/api/rfqs/getRFQs`
export const API_GET_RFQ_SUMMARY = `${API_URL_PREFIX}/api/rfqs/getRFQSummary`
export const API_GET_RFQ_BY_ID = `${API_URL_PREFIX}/api/rfqs/getRFQ/`
export const API_CREATE_RFQ = `${API_URL_PREFIX}/api/rfqs/createRFQ`
export const API_UPDATE_RFQ = `${API_URL_PREFIX}/api/rfqs/updateRFQ/`
export const API_DROP_RFQ = `${API_URL_PREFIX}/api/rfqs/dropRFQ/`
export const API_GET_PRODUCTS = `${API_URL_PREFIX}/api/products/getProducts`
export const API_GET_METAL_PRODUCTS = `${API_URL_PREFIX}/api/products/getMetalProducts`
export const API_GET_NORMAL_PRODUCTS = `${API_URL_PREFIX}/api/products/getNormalProducts`
export const API_ADD_PRODUCT = `${API_URL_PREFIX}/api/products/addProduct`
export const API_UPDATE_PRODUCT = `${API_URL_PREFIX}/api/products/updateProduct`
export const API_DELETE_PRODUCT = `${API_URL_PREFIX}/api/products/deleteProduct/`
export const API_GET_PRODUCT_SUMMARY = `${API_URL_PREFIX}/api/products/getProductSummary`
export const API_GET_NORMAL_PRODUCT_SUMMARY = `${API_URL_PREFIX}/api/products/getNormalProductSummary`
export const API_GET_HANDLING_SUMMARY = `${API_URL_PREFIX}/api/products/getHandlingSummary`
export const API_RESET_PRODUCT_SUM = `${API_URL_PREFIX}/api/products/resetProductSum`

export const API_GET_ORDERS = `${API_URL_PREFIX}/api/orders/getOrders`
export const API_GET_ORDER_SUMMARY = `${API_URL_PREFIX}/api/orders/getOrderSummary`
export const API_GET_ORDER_BY_ID = `${API_URL_PREFIX}/api/orders/getOrder/`
export const API_CREATE_ORDER = `${API_URL_PREFIX}/api/orders/createOrder`
export const API_CREATE_ORDER_WITH_INVENTORY = `${API_URL_PREFIX}/api/orders/createOrderwithInventory`
export const API_DROP_ORDER = `${API_URL_PREFIX}/api/orders/cancleOrder/`
export const API_CANCLE_ORDER_WITH_INVENTORY = `${API_URL_PREFIX}/api/orders/cancleOrderwithInventory/`
export const API_UPDATE_ORDER_BY_ID = `${API_URL_PREFIX}/api/orders/updateOrderWithInventory/`

export const API_BID_INVENTORY = `${API_URL_PREFIX}/api/inventory/bidInventory`
export const API_ASK_INVENTORY = `${API_URL_PREFIX}/api/inventory/askInventory`
export const API_TRANSFORM_INVENTORY = `${API_URL_PREFIX}/api/inventory/transformInventory`
export const API_UPDATE_INVENTORY_BY_ID = `${API_URL_PREFIX}/api/inventory/updateInventory/`
export const API_GET_INVENTORIES = `${API_URL_PREFIX}/api/inventory/getInventorys`
export const API_GET_INVENTORY_BY_ID = `${API_URL_PREFIX}/api/inventory/getInventory/`
export const API_DROP_INVENTORY_BY_ID = `${API_URL_PREFIX}/api/inventory/dropInventory/`
export const API_DELETE_INVENTORY_BY_ID = `${API_URL_PREFIX}/api/inventory/deleteInventory/`
export const API_GET_SALE_SUMMARY = `${API_URL_PREFIX}/api/inventory/getSaleSummary`

// export const API_GET_EXCHANGE = `${API_URL_PREFIX}/api/exchange/getExchangeRate`
export const API_GET_EXCHANGE = `${API_URL_PREFIX}/api/exchange/getSolarTechExchangeRate`